import React, { useImperativeHandle, useState, forwardRef } from 'react'
import {
  Modal,
  Form,
  Row,
  Col,
  message,
  Input,
  Button,
  Select
} from 'antd';
import { contractTempGetDetail } from '../../api'
const topCol = {
  span: 12,
}
const topCol24 = {
  span: 24,
}
const colSpan = {
  labelCol: { flex: '150px' }, wrapperCol: { flex: 1 }
}
const fieldLists = { 
  name: "姓名", 
  idNumber: "身份证号", 
  phone: "手机号", 
  companyName: "公司名称", 
  cityName: "城市", 
  storeName: "门店",
  brandName: "品牌名称",
  taxEnterpriseCode: "结算通道",
}
const ViewMoel = (props,ref) => {
  const [visible, setVisible] = useState(false);
  const [copyData, setCopyData] = useState({})
  const [form] = Form.useForm()
  const initialFormValues = {}
  const { Option } = Select
  // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(ref, () => ({
    // showModal 就是暴露给父组件的方法
    showModal: (record) => {
      form.resetFields()
      getDetail(record)
    }
  }));
  const getDetail = (record)=>{
    contractTempGetDetail({templateNo:record.templateNo}).then(res=>{
      if(res.data.success){
        setVisible(true);
        let data = res.data.data
        let fieldList = data.fieldList || []
        fieldList = fieldList.map(v=>{
          return fieldLists[v]
        })
        if(fieldList.length>0){
          data.fields = fieldList.join(',')
        }else{
          data.fields = '无'
        }
        setCopyData({...data})
        form.setFieldsValue({...data, cityNames: record.cityNames, signTypeName: data.signType==2? data.enterpriseName:'本企业' })
      }else{
          message.error(res.data.retMsg)
      }
    })
  }
  const showPdf = (fieldKey)=>{
     let values = form.getFieldValue()
     let pdfUrl = values.pdfUrl
     window.open(`/generic/web/viewer.html?file=${encodeURIComponent(pdfUrl)}`)
  }

  return (
    <>
    <Modal maskClosable={false} title="查看" width='1000px' forceRender visible={visible} onCancel={() => { setVisible(false) }} footer={null} >
      <Form initialValues={initialFormValues} form={form}>
        <Row className="margin-15">
          <Col {...topCol}>
            <Form.Item name='status' label='状态' {...colSpan}>
              <Select disabled showArrow={false} >
                <Option value={1}>启用</Option>
                <Option value={0}>停用</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col {...topCol}>
            <Form.Item name='createUser' label='创建人' {...colSpan}>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col {...topCol}>
            <Form.Item name='createTime' label='创建时间' {...colSpan}>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col {...topCol}>
            <Form.Item name='companyName' label='企业名称' {...colSpan}>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col {...topCol}>
            <Form.Item name='cityNames' label='适用城市' {...colSpan}>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col {...topCol}>
            <Form.Item name="signTypeName" label='承揽盖章企业' {...colSpan}>
              <Input disabled />
            </Form.Item>
          </Col>
          {/* {(copyData.signType==2)&&<Col {...topCol}>
            <Form.Item name='enterpriseName' label='结算通道' {...colSpan}>
              <Input disabled />
            </Form.Item>
          </Col>} */}
          <Col {...topCol}>
            <Form.Item label='签署文件' name='pdfUrl' {...colSpan} >
              <Button type="link" onClick={()=>{showPdf()}} >预览</Button>
            </Form.Item>
          </Col>
          <Col {...topCol24}>
            <Form.Item label='填充信息' name='fields' {...colSpan} >
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
    </>
  );
};
export default forwardRef(ViewMoel)



