let baseURL; 
let imgUrl = '';
if(process.env.NODE_ENV === 'development'){
  // baseURL = 'http://rap2api.taobao.org/app/mock/274053';        //开发环境
  // baseURL = 'http://192.168.19.94:8700';    //徐卉
  // baseURL = 'http://192.168.19.39:8700';   // 光松 
  // baseURL = 'https://admin.hire.wispay.cn'; // 正式环境
  baseURL = 'http://test.zchpay.com'; // 测试环境
  // baseURL = 'http://cb.zchpay.com'; //预发布
  // baseURL = 'http://192.168.19.63:8700';  //邱凯奇
}else{
  baseURL = '';         //生产环境
}

export {imgUrl, baseURL}